.ionicons {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    width: calc(100% / 8);
    font-size: 40px;
    padding: 40px 20px;
    list-style: none;
    text-align: center;
    border-radius: 3px;
    position: relative;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    .icon-name {
      position: absolute;
      top: 100%;
      left: 50%;
      width: 100%;
      -webkit-transform: translate(-50%, -100%);
      transform: translate(-50%, -100%);
      font-family: "Segoe UI";
      font-size: 12px;
      margin-top: 10px;
      line-height: 22px;
      background-color: #f9f9f9;
      border-radius: 3px;
      padding: 10px;
      display: none;
    }
  }
}

.icon-preview {
  width: 30px;

  i {
    font-size: 20px;
  }
}

.icon-container {
  .preview {
    display: flex;
    padding: 12px;
    border-radius: 5px;
    margin: 5px;
    border: 1px solid #c1bbbb;

    .icon-name {
      padding-left: 10px;
    }
  }
}

.icon-feather-container {
  div {
    padding: 12px;
    border: 1px solid #c1bbbb;
  }
}
