.media {
  .media-right {
    float: right;
    color: color(primary);
    font-weight: 600;
    font-size: 16px;
  }

  .media-icon {
    font-size: 20px;
    margin-right: 15px;
    line-height: 1;
  }

  .media-title {
    margin-top: 0;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 15px;
    color: color(fontdark);

    a {
      font-weight: inherit;
      color: #000;
    }
  }

  .media-description {
    line-height: 24px;
    color: color(fontdark);
  }

  .media-links {
    margin-top: 10px;

    a {
      font-size: 12px;
      color: #999;
    }
  }

  .media-progressbar {
    flex: 1;

    .progress-text {
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 5px;
      color: color(fontdark);
    }
  }

  .media-cta {
    margin-left: 40px;

    .btn {
      padding: 5px 15px;
      border-radius: 30px;
      font-size: 12px;
    }
  }

  .media-items {
    display: flex;

    .media-item {
      flex: 1;
      text-align: center;
      padding: 0 15px;

      .media-label {
        font-weight: 600;
        font-size: 12px;
        color: color(fontdark);
        letter-spacing: 0.5px;
      }

      .media-value {
        font-weight: 700;
        font-size: 18px;
      }
    }
  }
}
