.progress {
  -webkit-box-shadow: 0 0.4rem 0.6rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.4rem 0.6rem rgba(0, 0, 0, 0.15);

  &.progress-xs {
    height: 5px;
  }

  &.progress-s {
    height: 7px;
  }
}

.progress-bar {
  background-color: color(primary);
}
