/*
 *  Document   : _animation.scss
 *  Author     : RedStar Template
 *  Description: This scss file for animation css classes
 */
.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.waves-effect .waves-ripple {
  position: absolute;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
  opacity: 0;
  background: transparent;
  // background:rgba(0,0,0,0.2);
  // background:-webkit-radial-gradient(rgba(0,0,0,0.2) 0, rgba(0,0,0,0.3) 40%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0.5) 60%, rgba(255,255,255,0) 70%);
  // background:-o-radial-gradient(rgba(0,0,0,0.2) 0, rgba(0,0,0,0.3) 40%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0.5) 60%, rgba(255,255,255,0) 70%);
  // background:-moz-radial-gradient(rgba(0,0,0,0.2) 0, rgba(0,0,0,0.3) 40%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0.5) 60%, rgba(255,255,255,0) 70%);
  // background:radial-gradient(rgba(0,0,0,0.2) 0, rgba(0,0,0,0.3) 40%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0.5) 60%, rgba(255,255,255,0) 70%);
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -o-transition-property: -o-transform, opacity;
  transition-property: transform, opacity;
  -webkit-transform: scale(0) translate(0, 0);
  -moz-transform: scale(0) translate(0, 0);
  -ms-transform: scale(0) translate(0, 0);
  -o-transform: scale(0) translate(0, 0);
  transform: scale(0) translate(0, 0);
  pointer-events: none;
}

.waves-effect.waves-light .waves-ripple {
  background: rgba(255, 255, 255, 0.4);
  background: -webkit-radial-gradient(
    rgba(255, 255, 255, 0.2) 0,
    rgba(255, 255, 255, 0.3) 40%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0) 70%
  );
  background: -o-radial-gradient(
    rgba(255, 255, 255, 0.2) 0,
    rgba(255, 255, 255, 0.3) 40%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0) 70%
  );
  background: -moz-radial-gradient(
    rgba(255, 255, 255, 0.2) 0,
    rgba(255, 255, 255, 0.3) 40%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0) 70%
  );
  background: radial-gradient(
    rgba(255, 255, 255, 0.2) 0,
    rgba(255, 255, 255, 0.3) 40%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0) 70%
  );
}

.waves-effect.waves-classic .waves-ripple {
  background: rgba(0, 0, 0, 0.2);
}

.waves-effect.waves-classic.waves-light .waves-ripple {
  background: rgba(255, 255, 255, 0.4);
}

.waves-notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.waves-button,
.waves-circle {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-mask-image: -webkit-radial-gradient(circle, #fff 100%, #000 100%);
}

.waves-button,
.waves-button:hover,
.waves-button:visited,
.waves-button-input {
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  outline: none;
  color: inherit;
  background-color: transparent;
  font-size: 1em;
  line-height: 1em;
  text-align: center;
  text-decoration: none;
  z-index: 1;
}

.waves-button {
  padding: 0.85em 1.1em;
  border-radius: 0.2em;
}

.waves-button-input {
  margin: 0;
  padding: 0.85em 1.1em;
}

.waves-input-wrapper {
  border-radius: 0.2em;
  vertical-align: bottom;
}

.waves-input-wrapper.waves-button {
  padding: 0;
}

.waves-input-wrapper .waves-button-input {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}

.waves-circle {
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border-radius: 50%;
}

.waves-float {
  -webkit-mask-image: none;
  -webkit-box-shadow: 0px 1px 1.5px 1px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 1.5px 1px rgba(0, 0, 0, 0.12);
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
}

.waves-float:active {
  -webkit-box-shadow: 0px 8px 20px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 8px 20px 1px rgba(0, 0, 0, 0.3);
}

.waves-block {
  display: block;
}

.slideDown {
  animation-name: slideDown;
  -webkit-animation-name: slideDown;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }

  50% {
    transform: translateY(8%);
  }

  65% {
    transform: translateY(-4%);
  }

  80% {
    transform: translateY(4%);
  }

  95% {
    transform: translateY(-2%);
  }

  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-100%);
  }

  50% {
    -webkit-transform: translateY(8%);
  }

  65% {
    -webkit-transform: translateY(-4%);
  }

  80% {
    -webkit-transform: translateY(4%);
  }

  95% {
    -webkit-transform: translateY(-2%);
  }

  100% {
    -webkit-transform: translateY(0%);
  }
}

.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }

  50% {
    transform: translateY(-8%);
  }

  65% {
    transform: translateY(4%);
  }

  80% {
    transform: translateY(-4%);
  }

  95% {
    transform: translateY(2%);
  }

  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%);
  }

  50% {
    -webkit-transform: translateY(-8%);
  }

  65% {
    -webkit-transform: translateY(4%);
  }

  80% {
    -webkit-transform: translateY(-4%);
  }

  95% {
    -webkit-transform: translateY(2%);
  }

  100% {
    -webkit-transform: translateY(0%);
  }
}

.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important;
}

@keyframes slideLeft {
  0% {
    transform: translateX(150%);
  }

  50% {
    transform: translateX(-8%);
  }

  65% {
    transform: translateX(4%);
  }

  80% {
    transform: translateX(-4%);
  }

  95% {
    transform: translateX(2%);
  }

  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%);
  }

  50% {
    -webkit-transform: translateX(-8%);
  }

  65% {
    -webkit-transform: translateX(4%);
  }

  80% {
    -webkit-transform: translateX(-4%);
  }

  95% {
    -webkit-transform: translateX(2%);
  }

  100% {
    -webkit-transform: translateX(0%);
  }
}

.slideRight {
  animation-name: slideRight;
  -webkit-animation-name: slideRight;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important;
}

@keyframes slideRight {
  0% {
    transform: translateX(-150%);
  }

  50% {
    transform: translateX(8%);
  }

  65% {
    transform: translateX(-4%);
  }

  80% {
    transform: translateX(4%);
  }

  95% {
    transform: translateX(-2%);
  }

  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slideRight {
  0% {
    -webkit-transform: translateX(-150%);
  }

  50% {
    -webkit-transform: translateX(8%);
  }

  65% {
    -webkit-transform: translateX(-4%);
  }

  80% {
    -webkit-transform: translateX(4%);
  }

  95% {
    -webkit-transform: translateX(-2%);
  }

  100% {
    -webkit-transform: translateX(0%);
  }
}

.slideExpandUp {
  animation-name: slideExpandUp;
  -webkit-animation-name: slideExpandUp;
  animation-duration: 1.6s;
  -webkit-animation-duration: 1.6s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease -out;
  visibility: visible !important;
}

@keyframes slideExpandUp {
  0% {
    transform: translateY(100%) scaleX(0.5);
  }

  30% {
    transform: translateY(-8%) scaleX(0.5);
  }

  40% {
    transform: translateY(2%) scaleX(0.5);
  }

  50% {
    transform: translateY(0%) scaleX(1.1);
  }

  60% {
    transform: translateY(0%) scaleX(0.9);
  }

  70% {
    transform: translateY(0%) scaleX(1.05);
  }

  80% {
    transform: translateY(0%) scaleX(0.95);
  }

  90% {
    transform: translateY(0%) scaleX(1.02);
  }

  100% {
    transform: translateY(0%) scaleX(1);
  }
}

@-webkit-keyframes slideExpandUp {
  0% {
    -webkit-transform: translateY(100%) scaleX(0.5);
  }

  30% {
    -webkit-transform: translateY(-8%) scaleX(0.5);
  }

  40% {
    -webkit-transform: translateY(2%) scaleX(0.5);
  }

  50% {
    -webkit-transform: translateY(0%) scaleX(1.1);
  }

  60% {
    -webkit-transform: translateY(0%) scaleX(0.9);
  }

  70% {
    -webkit-transform: translateY(0%) scaleX(1.05);
  }

  80% {
    -webkit-transform: translateY(0%) scaleX(0.95);
  }

  90% {
    -webkit-transform: translateY(0%) scaleX(1.02);
  }

  100% {
    -webkit-transform: translateY(0%) scaleX(1);
  }
}

.expandUp {
  animation-name: expandUp;
  -webkit-animation-name: expandUp;
  animation-duration: 0.7s;
  -webkit-animation-duration: 0.7s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important;
}

@keyframes expandUp {
  0% {
    transform: translateY(100%) scale(0.6) scaleY(0.5);
  }

  60% {
    transform: translateY(-7%) scaleY(1.12);
  }

  75% {
    transform: translateY(3%);
  }

  100% {
    transform: translateY(0%) scale(1) scaleY(1);
  }
}

@-webkit-keyframes expandUp {
  0% {
    -webkit-transform: translateY(100%) scale(0.6) scaleY(0.5);
  }

  60% {
    -webkit-transform: translateY(-7%) scaleY(1.12);
  }

  75% {
    -webkit-transform: translateY(3%);
  }

  100% {
    -webkit-transform: translateY(0%) scale(1) scaleY(1);
  }
}

.fadeIn {
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important;
}

@keyframes fadeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  60% {
    transform: scale(1.1);
  }

  80% {
    transform: scale(0.9);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  60% {
    -webkit-transform: scale(1.1);
  }

  80% {
    -webkit-transform: scale(0.9);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

.expandOpen {
  animation-name: expandOpen;
  -webkit-animation-name: expandOpen;
  animation-duration: 1.2s;
  -webkit-animation-duration: 1.2s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  visibility: visible !important;
}

@keyframes expandOpen {
  0% {
    transform: scale(1.8);
  }

  50% {
    transform: scale(0.95);
  }

  80% {
    transform: scale(1.05);
  }

  90% {
    transform: scale(0.98);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes expandOpen {
  0% {
    -webkit-transform: scale(1.8);
  }

  50% {
    -webkit-transform: scale(0.95);
  }

  80% {
    -webkit-transform: scale(1.05);
  }

  90% {
    -webkit-transform: scale(0.98);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

.bigEntrance {
  animation-name: bigEntrance;
  -webkit-animation-name: bigEntrance;
  animation-duration: 1.6s;
  -webkit-animation-duration: 1.6s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  visibility: visible !important;
}

@keyframes bigEntrance {
  0% {
    transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
    opacity: 0.2;
  }

  30% {
    transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
    opacity: 1;
  }

  45% {
    transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }

  60% {
    transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }

  75% {
    transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }

  90% {
    transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }

  100% {
    transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
}

@-webkit-keyframes bigEntrance {
  0% {
    -webkit-transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
    opacity: 0.2;
  }

  30% {
    -webkit-transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
    opacity: 1;
  }

  45% {
    -webkit-transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }

  60% {
    -webkit-transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }

  75% {
    -webkit-transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1;
  }

  90% {
    -webkit-transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1;
  }
}

.hatch {
  animation-name: hatch;
  -webkit-animation-name: hatch;
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
  visibility: visible !important;
}

@keyframes hatch {
  0% {
    transform: rotate(0deg) scaleY(0.6);
  }

  20% {
    transform: rotate(-2deg) scaleY(1.05);
  }

  35% {
    transform: rotate(2deg) scaleY(1);
  }

  50% {
    transform: rotate(-2deg);
  }

  65% {
    transform: rotate(1deg);
  }

  80% {
    transform: rotate(-1deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes hatch {
  0% {
    -webkit-transform: rotate(0deg) scaleY(0.6);
  }

  20% {
    -webkit-transform: rotate(-2deg) scaleY(1.05);
  }

  35% {
    -webkit-transform: rotate(2deg) scaleY(1);
  }

  50% {
    -webkit-transform: rotate(-2deg);
  }

  65% {
    -webkit-transform: rotate(1deg);
  }

  80% {
    -webkit-transform: rotate(-1deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

.bounce {
  animation-name: bounce;
  -webkit-animation-name: bounce;
  animation-duration: 1.6s;
  -webkit-animation-duration: 1.6s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
}

@keyframes bounce {
  0% {
    transform: translateY(0%) scaleY(0.6);
  }

  60% {
    transform: translateY(-100%) scaleY(1.1);
  }

  70% {
    transform: translateY(0%) scaleY(0.95) scaleX(1.05);
  }

  80% {
    transform: translateY(0%) scaleY(1.05) scaleX(1);
  }

  90% {
    transform: translateY(0%) scaleY(0.95) scaleX(1);
  }

  100% {
    transform: translateY(0%) scaleY(1) scaleX(1);
  }
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0%) scaleY(0.6);
  }

  60% {
    -webkit-transform: translateY(-100%) scaleY(1.1);
  }

  70% {
    -webkit-transform: translateY(0%) scaleY(0.95) scaleX(1.05);
  }

  80% {
    -webkit-transform: translateY(0%) scaleY(1.05) scaleX(1);
  }

  90% {
    -webkit-transform: translateY(0%) scaleY(0.95) scaleX(1);
  }

  100% {
    -webkit-transform: translateY(0%) scaleY(1) scaleX(1);
  }
}

.pulse {
  animation-name: pulse;
  -webkit-animation-name: pulse;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 0.7;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0.9);
    opacity: 0.7;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
    opacity: 0.7;
  }

  50% {
    -webkit-transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(0.95);
    opacity: 0.7;
  }
}

.floating {
  animation-name: floating;
  -webkit-animation-name: floating;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

@keyframes floating {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(8%);
  }

  100% {
    transform: translateY(0%);
  }
}

@-webkit-keyframes floating {
  0% {
    -webkit-transform: translateY(0%);
  }

  50% {
    -webkit-transform: translateY(8%);
  }

  100% {
    -webkit-transform: translateY(0%);
  }
}

.tossing {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  animation-duration: 2.5s;
  -webkit-animation-duration: 2.5s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

@keyframes tossing {
  0% {
    transform: rotate(-4deg);
  }

  50% {
    transform: rotate(4deg);
  }

  100% {
    transform: rotate(-4deg);
  }
}

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-4deg);
  }

  50% {
    -webkit-transform: rotate(4deg);
  }

  100% {
    -webkit-transform: rotate(-4deg);
  }
}

.pullUp {
  animation-name: pullUp;
  -webkit-animation-name: pullUp;
  animation-duration: 1.1s;
  -webkit-animation-duration: 1.1s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
}

@keyframes pullUp {
  0% {
    transform: scaleY(0.1);
  }

  40% {
    transform: scaleY(1.02);
  }

  60% {
    transform: scaleY(0.98);
  }

  80% {
    transform: scaleY(1.01);
  }

  100% {
    transform: scaleY(0.98);
  }

  80% {
    transform: scaleY(1.01);
  }

  100% {
    transform: scaleY(1);
  }
}

@-webkit-keyframes pullUp {
  0% {
    -webkit-transform: scaleY(0.1);
  }

  40% {
    -webkit-transform: scaleY(1.02);
  }

  60% {
    -webkit-transform: scaleY(0.98);
  }

  80% {
    -webkit-transform: scaleY(1.01);
  }

  100% {
    -webkit-transform: scaleY(0.98);
  }

  80% {
    -webkit-transform: scaleY(1.01);
  }

  100% {
    -webkit-transform: scaleY(1);
  }
}

.pullDown {
  animation-name: pullDown;
  -webkit-animation-name: pullDown;
  animation-duration: 1.1s;
  -webkit-animation-duration: 1.1s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  -webkit-transform-origin: 50% 0%;
}

@keyframes pullDown {
  0% {
    transform: scaleY(0.1);
  }

  40% {
    transform: scaleY(1.02);
  }

  60% {
    transform: scaleY(0.98);
  }

  80% {
    transform: scaleY(1.01);
  }

  100% {
    transform: scaleY(0.98);
  }

  80% {
    transform: scaleY(1.01);
  }

  100% {
    transform: scaleY(1);
  }
}

@-webkit-keyframes pullDown {
  0% {
    -webkit-transform: scaleY(0.1);
  }

  40% {
    -webkit-transform: scaleY(1.02);
  }

  60% {
    -webkit-transform: scaleY(0.98);
  }

  80% {
    -webkit-transform: scaleY(1.01);
  }

  100% {
    -webkit-transform: scaleY(0.98);
  }

  80% {
    -webkit-transform: scaleY(1.01);
  }

  100% {
    -webkit-transform: scaleY(1);
  }
}

.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%;
}

@keyframes stretchLeft {
  0% {
    transform: scaleX(0.3);
  }

  40% {
    transform: scaleX(1.02);
  }

  60% {
    transform: scaleX(0.98);
  }

  80% {
    transform: scaleX(1.01);
  }

  100% {
    transform: scaleX(0.98);
  }

  80% {
    transform: scaleX(1.01);
  }

  100% {
    transform: scaleX(1);
  }
}

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3);
  }

  40% {
    -webkit-transform: scaleX(1.02);
  }

  60% {
    -webkit-transform: scaleX(0.98);
  }

  80% {
    -webkit-transform: scaleX(1.01);
  }

  100% {
    -webkit-transform: scaleX(0.98);
  }

  80% {
    -webkit-transform: scaleX(1.01);
  }

  100% {
    -webkit-transform: scaleX(1);
  }
}

.stretchRight {
  animation-name: stretchRight;
  -webkit-animation-name: stretchRight;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
}

@keyframes stretchRight {
  0% {
    transform: scaleX(0.3);
  }

  40% {
    transform: scaleX(1.02);
  }

  60% {
    transform: scaleX(0.98);
  }

  80% {
    transform: scaleX(1.01);
  }

  100% {
    transform: scaleX(0.98);
  }

  80% {
    transform: scaleX(1.01);
  }

  100% {
    transform: scaleX(1);
  }
}

@-webkit-keyframes stretchRight {
  0% {
    -webkit-transform: scaleX(0.3);
  }

  40% {
    -webkit-transform: scaleX(1.02);
  }

  60% {
    -webkit-transform: scaleX(0.98);
  }

  80% {
    -webkit-transform: scaleX(1.01);
  }

  100% {
    -webkit-transform: scaleX(0.98);
  }

  80% {
    -webkit-transform: scaleX(1.01);
  }

  100% {
    -webkit-transform: scaleX(1);
  }
}

.pulsate {
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 1;
}

.bellAnim {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 40px;
  margin: 5px auto 0;
  color: #9e9e9e;
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

.mailAnim {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 40px;
  margin: 5px auto 0;
  color: #9e9e9e;
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }

  1% {
    -moz-transform: rotate(30deg);
  }

  3% {
    -moz-transform: rotate(-28deg);
  }

  5% {
    -moz-transform: rotate(34deg);
  }

  7% {
    -moz-transform: rotate(-32deg);
  }

  9% {
    -moz-transform: rotate(30deg);
  }

  11% {
    -moz-transform: rotate(-28deg);
  }

  13% {
    -moz-transform: rotate(26deg);
  }

  15% {
    -moz-transform: rotate(-24deg);
  }

  17% {
    -moz-transform: rotate(22deg);
  }

  19% {
    -moz-transform: rotate(-20deg);
  }

  21% {
    -moz-transform: rotate(18deg);
  }

  23% {
    -moz-transform: rotate(-16deg);
  }

  25% {
    -moz-transform: rotate(14deg);
  }

  27% {
    -moz-transform: rotate(-12deg);
  }

  29% {
    -moz-transform: rotate(10deg);
  }

  31% {
    -moz-transform: rotate(-8deg);
  }

  33% {
    -moz-transform: rotate(6deg);
  }

  35% {
    -moz-transform: rotate(-4deg);
  }

  37% {
    -moz-transform: rotate(2deg);
  }

  39% {
    -moz-transform: rotate(-1deg);
  }

  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
